import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Header, TechnologiesUsed, Footer, Image } from "../components";

const VacasaProjectStyles = styled.div`
  padding: 17px;
  .project-description {
    width: 66.6667%;
    margin: 0 auto 80px;
  }
  p,
  ul {
    color: ${(props) => props.theme.text.paragraph};
  }
  h1 {
    text-align: center;
  }
  .project-description p {
    text-align: left;
    color: ${(props) => props.theme.text.paragraph};
  }
  .about-the-project-container {
    width: 66.667%;
    margin: auto;
  }

  .company-img-container {
    display: flex;
    justify-content: center;
    padding: 17px 0;
  }
  .company-img-container {
    width: 20%;
    margin: auto;
  }
  .client-description-quote {
    border-left: 1px solid #ddd;
    padding-left: 1.618em;
    margin: 0;
  }
  .clients-words-container {
    margin-top: 60px;
  }
  .project-summary-container {
    margin-top: 90px;
  }
  .vacasa-img-container {
    display: flex;
    justify-content: center;
    padding: 30px;
  }
`;

const gridItems = [
  { link: "", imageName: "react", text: "react" },
  { link: "", imageName: "reduxImage", text: "redux" },
  { link: "", imageName: "python", text: "python" },
  { link: "", imageName: "d3", text: "d3" },
  { link: "", imageName: "redisImage", text: "redis" },
  { link: "", imageName: "dockerImage", text: "docker" },
  { link: "", imageName: "amazon-web-services", text: "amazon" },
  { link: "", imageName: "lookerImage", text: "looker" },
];

const bulletItems = [
  {
    bullet: "Front-end",
    subBullets: [
      { bullet: "React" },
      { bullet: "D3.js - Creating charts integrated with React" },
      { bullet: "Redux" },
      {
        bullet:
          "Bootstrap - Charts are a mix of responsive HTML/SVG to work on mobile",
      },
    ],
  },
  {
    bullet: "API",
    subBullets: [
      { bullet: "Python" },
      { bullet: "Flask" },
      { bullet: "Using SAML for integration with Vacasa Single Sign On" },
      { bullet: "Integration with the Looker API" },
    ],
  },
  {
    bullet: "DevOps/Hosting",
    subBullets: [
      { bullet: "Redis for a catching layer in front of Looker API" },
      {
        bullet:
          "Dockerfiles created to easily integrate into client's architecture",
      },
      {
        bullet:
          "Test site hosted in Amazon Web Services using Elastic Container Registry (ECR) and Elastic Container Service (ECS)",
      },
    ],
  },
];

const TSheetsChromeAppPage = () => (
  <main>
    <Header />
    <VacasaProjectStyles>
      <div className="project-description">
        <h1>Vacasa</h1>
        <h1>Executive Dashboard</h1>
        <p>
          We created an Executive Dashboard for Vacasa to visualize their
          critical Key Performance Indicators (KPI) across the entire company.
          Vacasa uses Looker for business intelligence, dashboards & reporting.
          Looker is connected to an Amazon Redshift database that contains
          information flowing in from multiple data sources. The goal of the
          Executive Dashboard was to identify those key metrics that were most
          critical to monitor the business, and display them within a polished,
          branded web application that is functional, informative and engaging.
          Having this information centrally available in one location helps to
          facilitate better communication within Vacasa as business teams focus
          on the same things, improving business results and driving positive
          change.
        </p>
        <p>
          The app protects all data behind a login, so no live link available.
        </p>
      </div>
      <TechnologiesUsed
        gridItems={gridItems}
        bulletItems={bulletItems}
        columnCount={3}
      />
      <div className="about-the-project-container">
        <h3>About the Client (Vacasa)</h3>
        <div className="company-img-container">
          <Image imageName="vacasa" alt="Vacasa" />
        </div>
        <p>
          <a href="https://www.vacasa.com/">
            <span>Vacasa</span>
          </a>{" "}
          is the largest vacation rental management company in the U.S., Vacasa
          uses industry-leading technology to better care for guests and
          maximize financial returns for vacation homeowners. In support of its
          global operations, Vacasa has physical offices around the world with a
          growing presence here in Boise.
        </p>
        <div className="client-description-quote">
          <p>
            &quot;It&apos;s always a pleasure working with a client that cares
            about the details that go into a product, yet is practical enough to
            know what it takes to deliver great software. It&apos;s also very
            impressive to realize how many metrics drive the company forward and
            their collective buy-in to be a data-driven organization.&quot;
            <br />
            -- Jeremy Zerr, Founder, Zerrtech
          </p>
        </div>
        <div className="clients-words-container">
          <h3>In the Client&apos;s Words</h3>
          <div className="client-description-quote">
            <p>
              &quot;Working with Jeremy and Zerrtech has been incredibly
              positive. After a brief POC we were convinced they had the right
              combination of communication skills, business understanding, and
              technical expertise to help take our project over the finish line.
              I would use them again in a heartbeat!&quot; <br /> -- Keith
              Smith, Director of Business Intelligence, Vacasa
            </p>
          </div>
        </div>
        <div className="project-summary-container">
          <h3>Project Role</h3>
          <p>
            Our roles in this project included:
            <ul>
              <li>Software Architecture and Design</li>
              <li>Coding all responsive HTML5, CSS, Javascript</li>
              <li>Creating all D3.js SVG charts and integrating with React</li>
              <li>
                Creating Dockerfile to be able to host in client&apos;s AWS
              </li>
              <li>
                Creating a system to email dashboard charts to users via an
                automated, background process
              </li>
            </ul>
          </p>
          <h3>Feature Summary</h3>
          <p>
            Due to the sensitive nature of the KPIs and data, we can&apos;t show
            some of the screenshots, Here is a high level summary of the
            Executive Dashboard features:
            <ul>
              <li>
                Company metrics from the Looker API displayed in charts built
                using D3.js integrated with React + Redux
              </li>
              <li>
                Chart types include bullet charts, sparklines, streamgraphs,
                geographic data, bar charts, line charts.
              </li>
              <li>
                Nearly all charts include interactivity, including hover over
                tooltips and highlighting, dynamic color palette conforming to
                brand standards when appropriate, and some drill down details.
              </li>
              <li>
                Integrated with Vacasa Single Sign On for a seamless experience
                for Vacasa executives and employees, and protect all access to
                the underlying data
              </li>
            </ul>
            Bullet chart showing data when you hover.
            <div className="vacasa-img-container">
              <Image
                imageName="feature-summary-img-1"
                alt="Feature Summary Image 1"
              />
            </div>
            Sparkline chart showing data when you hover.
            <div className="vacasa-img-container">
              <Image
                imageName="feature-summary-img-2"
                alt="Feature Summary Image 2"
              />
            </div>
            Streamgraph showing categories changing over time. There is a hover
            that shows when you select one of the categories of the chart, but
            leaving it out for privacy reasons.
            <div className="vacasa-img-container">
              <Image
                imageName="feature-summary-img-3"
                alt="Feature Summary Image 3"
              />
            </div>
            Geographic data showing data for states.
            <div className="vacasa-img-container">
              <Image
                imageName="feature-summary-img-4"
                alt="Feature Summary Image 4"
              />
            </div>
          </p>
          <h3>Technology Details</h3>
          <p>
            React + D3.js was a great fit for this project. The combination of
            technologies made it simple to build reusable chart components and
            have them update to reflect changes fetched from the API. We used
            Redux to store the data to provide organization around the many API
            calls and data shapes. We enjoyed taking on the styling challenge of
            aligning groups of D3.js SVG visualizations in a responsive
            environment.
          </p>
          <p>
            The Python + Flask API allowed us to simply handle the security
            requirements of integrating with a Single Sign On system using SAML.
          </p>
          <p>
            We used Docker to create a self-contained project that would be easy
            to integrate into the client&apos;s existing AWS infrastructure. We
            used Docker compose to enable our local development environment to
            be as close as possible to the production environment.
          </p>
        </div>
        <div className="contact-us-container">
          <div className="vacasa-img-container">
            <Image imageName="header-logo" alt="Zerrtech" />
          </div>
          <h3>
            What can we help you build?{" "}
            <Link href="/contact">
              <span>Contact Us</span>
            </Link>
          </h3>
        </div>
      </div>
    </VacasaProjectStyles>
    <Footer />
  </main>
);

export default TSheetsChromeAppPage;
